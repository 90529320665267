'use client';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useState } from 'react';

import { ACTIONS, CATEGORIES, CHANNEL_LABELS } from '@/constants/analytics';
import { TInboundFormData } from '@/containers/InboundV2/types';
import { useMgm } from '@/hooks/useMgm';
import { useRex } from '@/hooks/useRex';
import useStore from '@/store';
import { GAsendEvent, getTranckerInfo } from '@/utils/Analytics';
import { bffHttp, useHttpClient } from '@/utils/http-client';
import { formatOnlyNumbers } from '@/utils/masks';
import { translateTpvOption } from '@/utils/translateTpvOption';

import InboundV2Forms from '../../components/InboundV2Forms';
import InboundV2SubmitModal from '../../components/InboundV2SubmitModal';
import { isOutOfAttendanceTime } from '../../helpers/isOutOfAttendanceTime';
import { sendInboundsMgmEntryData } from '../../services/sendInboundsMgmEntryData';
import { sendOneApiWhatsAppData } from '../../services/sendOneApiWhatsAppData';

type InboundV2DefaultFormsContainerProps = {
  shouldShowFormsTitle?: boolean;
  analyticsEventType?: string;
  analyticsSectionReference?: string;
};

const InboundV2DefaultFormsContainer: React.FC<
  InboundV2DefaultFormsContainerProps
> = ({
  shouldShowFormsTitle,
  analyticsEventType,
  analyticsSectionReference,
}) => {
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const store = useStore();
  const [isMgm] = useMgm();
  const isRex = useRex();

  const httpClient = useHttpClient(bffHttp);

  const isREXOrMGM = isMgm || isRex;

  const handleInboundMgmFormData = async (event: TInboundFormData) => {
    sendInboundsMgmEntryData(httpClient.fetch, {
      document: event.document.replace(/[^a-z\d\s]+/gi, ''),
      full_name: event.name,
      phone: formatOnlyNumbers(event.phone),
      email: event.email,
      tpv: translateTpvOption(event.tpv),
      tracking: {
        coupon: searchParams?.get('coupon') || store.coupon,
        utm_medium: searchParams?.get('utm_medium') || store.utm_medium,
        utm_source: searchParams?.get('utm_source') || store.utm_source,
        utm_campaign: searchParams?.get('utm_campaign') || store.utm_campaign,
        utm_content: searchParams?.get('utm_content') || '',
        utm_term: searchParams?.get('utm_term') || store.utm_term,
        utm_placement: searchParams?.get('utm_placement') || '',
        fbid: searchParams?.get('fbid') || '',
        gcid: searchParams?.get('gcid') || '',
        client_id: searchParams?.get('client_id') || '',
        user_id: searchParams?.get('user_id') || '',
      },
    });
  };

  const triggerWhatsappMessage = async (event: TInboundFormData) => {
    const { gaClientId, gaTrackingId } = await getTranckerInfo();

    if (isOutOfAttendanceTime()) {
      event.leadSourceGroup = 'adquira_madrugada';
    }

    if (searchParams?.get('coupon')) {
      event.cupom = searchParams?.get('coupon') ?? '';
    }

    event.source = 'inbound_form';
    if (
      pathname?.includes('/solucao-completa/') ||
      pathname?.includes('/solucao-completa')
    ) {
      event.source = 'inbound_multiproduto';
    }
    event.gaClientId = gaClientId;
    event.gaTrackingId = gaTrackingId;

    await sendOneApiWhatsAppData({
      ...event,
      channel: 'whatsapp',
    });
  };

  const handleMetricsEvents = async (event: TInboundFormData) => {
    window.dataLayer?.push({
      event: 'inbound_form_submitted',
      channel: 'whatsapp',
      tpv: event.tpv,
    });

    GAsendEvent(CATEGORIES.INBOUND_LEAD, CHANNEL_LABELS.WHASTSAPP, event.tpv);
    GAsendEvent(
      CATEGORIES.INBOUND_FORM,
      ACTIONS.ADQUIRA,
      CHANNEL_LABELS.WHASTSAPP,
    );
  };

  const handleSubmitForms = async (event: TInboundFormData) => {
    const isSolucaoCompleta = pathname?.includes('/solucao-completa');
    const protocol = isSolucaoCompleta ? 'ADQ-MULTI' : 'ADQ-STN';
    window.open(
      `https://api.whatsapp.com/send?phone=551140401946&text=Protocolo de Atendimento: ${protocol} | Oi! Você pode me ajudar?`,
      '_blank',
      'noopener,noreferrer',
    );

    await triggerWhatsappMessage(event);
    await handleMetricsEvents(event);
    isREXOrMGM ? handleInboundMgmFormData(event) : null;

    setTimeout(() => router.push('/'), 5000);
  };

  const handleModalBackButton = () => {
    const isInboundDomain = pathname?.includes('/adquira');
    const mgmPlansPageWithQueryParams = `/planos-mgm?${searchParams?.toString()}`;

    if (isREXOrMGM) {
      return router.push(mgmPlansPageWithQueryParams);
    }
    if (isInboundDomain) {
      return router.push('/');
    }

    setShowFinishModal(false);
  };

  return (
    <>
      <InboundV2SubmitModal
        showModal={showFinishModal}
        isOutOfAttendanceTime={isOutOfAttendanceTime()}
        onBackToSite={handleModalBackButton}
      />
      <InboundV2Forms
        onSubmit={event => {
          return handleSubmitForms(event);
        }}
        shouldShowTitle={shouldShowFormsTitle}
        analyticsEventType={analyticsEventType}
        analyticsSectionReference={analyticsSectionReference}
      />
    </>
  );
};

export default InboundV2DefaultFormsContainer;
