'use client';

import dynamic from 'next/dynamic';
import { useMemo } from 'react';

import SpritedIcons from './SpritedIcons';
import ArrowIcons from './SVGs/1x/Arrows';
import EmotionsHealthIcons from './SVGs/1x/EmotionsHealth';
import FinanceStoneIcons from './SVGs/1x/FinanceStone';
import ObjectsIcons from './SVGs/1x/Objects';
import SecurityTechnology from './SVGs/1x/SecurityTechnology';
import SignalsIcons from './SVGs/1x/Signals';
import SocialsIcons from './SVGs/1x/Socials';
import ArrowIcons2x from './SVGs/2x/Arrows';
import EmotionsHealthIcons2x from './SVGs/2x/EmotionsHealth';
import FinanceStoneIcons2x from './SVGs/2x/FinanceStone';
import ObjectsIcons2x from './SVGs/2x/Objects';
import SecurityTechnology2x from './SVGs/2x/SecurityTechnology';
import SignalsIcons2x from './SVGs/2x/Signals';
import SocialsIcons2x from './SVGs/2x/Socials';

export const Icons = {
  /* Old Icons - Remove from project */
  'logo-ton-stone': dynamic(() => import('./icons/logo/TonStone')),
  'agent-stone': dynamic(() => import('./icons/AgentStone')),
  'b-chart-small': dynamic(() => import('./icons/BChartSmall')),
  'double-cards': dynamic(() => import('./icons/DoubleCards')),
  calendar: dynamic(() => import('./icons/Calendar')),
  cart: dynamic(() => import('./icons/Cart')),
  coin: dynamic(() => import('./icons/Coin')),
  coins: dynamic(() => import('./icons/Coins')),
  'coins-stack-shine': dynamic(() => import('./icons/CoinsStackShine')),
  'chevron-right': dynamic(() => import('./icons/ChevronRight')),
  'chevron-left': dynamic(() => import('./icons/ChevronLeft')),
  close: dynamic(() => import('./icons/Close')),
  coil: dynamic(() => import('./icons/Coil')),
  'flip-up2': dynamic(() => import('./icons/FlipUp2')),
  contactless: dynamic(() => import('./icons/Contactless')),
  'contactless-card': dynamic(() => import('./icons/ContactlessCard')),
  'credit-card': dynamic(() => import('./icons/CreditCard')),
  coupon: dynamic(() => import('./icons/Coupon')),
  'customer-support': dynamic(() => import('./icons/CustomerSupport')),
  'customer-support-2x': dynamic(() => import('./icons/CustomerSupport2x')),
  'easy-turning': dynamic(() => import('./icons/EasyTurning')),
  'flip-up': dynamic(() => import('./icons/FlipUp')),
  global: dynamic(() => import('./icons/Global')),
  'hand-card-machine': dynamic(() => import('./icons/HandCardMachine')),
  'hand-heart-soft': dynamic(() => import('./icons/HandHeartSoft')),
  'hand-money': dynamic(() => import('./icons/HandMoney')),
  'hand-qr-code': dynamic(() => import('./icons/HandQrCode')),
  'hands-using-app': dynamic(() => import('./icons/HandsUsingApp')),
  handshake: dynamic(() => import('./icons/Handshake')),
  'hang-loose': dynamic(() => import('./icons/HangLoose')),
  'head-phone': dynamic(() => import('./icons/HeadPhone')),
  heart: dynamic(() => import('./icons/Heart')),
  'heart-24': dynamic(() => import('./icons/Heart24')),
  clock: dynamic(() => import('./icons/Clock')),
  libras: dynamic(() => import('./icons/Libras')),
  'clock-24': dynamic(() => import('./icons/Clock24')),
  lock: dynamic(() => import('./icons/Lock')),
  'life-buoy': dynamic(() => import('./icons/LifeBuoy')),
  'machine-tablet': dynamic(() => import('./icons/MachineTablet')),
  'mobile-banking': dynamic(() => import('./icons/MobileBanking')),
  'mobile-card': dynamic(() => import('./icons/MobileCard')),
  'money-hand-24': dynamic(() => import('./icons/MoneyHand24')),
  'money-time': dynamic(() => import('./icons/MoneyTime')),
  'papo-de-balcao': dynamic(() => import('./icons/PapoDeBalcao')),
  'payment-link': dynamic(() => import('./icons/PaymentLink')),
  percent: dynamic(() => import('./icons/Percent')),
  'phone-check': dynamic(() => import('./icons/PhoneCheck')),
  'ecommerce-2x': dynamic(() => import('./icons/Ecommerce2x')),
  'qr-code-pix': dynamic(() => import('./icons/QrCodePix')),
  'pix-in-lote': dynamic(() => import('./icons/PixInLote')),
  question: dynamic(() => import('./icons/Question')),
  'raio-x': dynamic(() => import('./icons/RaioX')),
  'round-dollar': dynamic(() => import('./icons/RoundDollar')),
  'smart-phone': dynamic(() => import('./icons/SmartPhone')),
  stone: dynamic(() => import('./icons/Stone')),
  'stone-card': dynamic(() => import('./icons/StoneCard')),
  'swap-horizontal': dynamic(() => import('./icons/SwapHorizontal')),
  tag: dynamic(() => import('./icons/Tag')),
  pounds: dynamic(() => import('./icons/Pounds')),
  'tag-loyalty': dynamic(() => import('./icons/TagLoyalty')),
  tablet: dynamic(() => import('./icons/Tablet')),
  users: dynamic(() => import('./icons/Users')),
  'users-2x': dynamic(() => import('./icons/users-2x')),
  whatsapp: dynamic(() => import('./icons/Whatsapp')),
  pig: dynamic(() => import('./icons/Pig')),
  'cash-phone': dynamic(() => import('./icons/CashPhone')),
  'cash-round': dynamic(() => import('./icons/CashRound')),
  pix: dynamic(() => import('./icons/Pix')),
  'star-24': dynamic(() => import('./icons/Star24')),
  'handshake-soft': dynamic(() => import('./icons/HandshakeSoft')),
  'store-shop': dynamic(() => import('./icons/StoreShop')),
  'box-gift': dynamic(() => import('./icons/BoxGift')),
  gift: dynamic(() => import('./icons/Gift')),
  gift2x: dynamic(() => import('./icons/Gift2x')),
  cutlery: dynamic(() => import('./icons/Cutlery')),
  shop2: dynamic(() => import('./icons/Shop2')),
  'animation/chat': dynamic(() => import('./icons/animations/Chat')),
  'animation/phone': dynamic(() => import('./icons/animations/Phone')),
  'animation/whatsapp': dynamic(() => import('./icons/animations/Whatsapp')),
  'logo/stone': dynamic(() => import('./icons/logo/Stone')),
  financeAndStone: dynamic(() => import('./icons/FinanceAndStone')),
  healthFaceHappy: dynamic(() => import('./icons/HealthFaceHappy')),
  signalFormStar: dynamic(() => import('./icons/SignalsFormStar')),
  'arrow-fill-left': dynamic(() => import('./icons/ArrowFillLeft')),
  'duo-stars': dynamic(() => import('./icons/DuoStars')),
  'one-stars': dynamic(() => import('./icons/OneStars')),
  'free-machine': dynamic(() => import('./icons/FreeMachine')),
  // start Stonjis
  'stonji/agent': dynamic(() => import('./icons/stonjis/Agent')),
  'stonji/check': dynamic(() => import('./icons/stonjis/Check')),

  'stonji/money': dynamic(() => import('./icons/stonjis/Money')),
  'stonji/chart-black': dynamic(() => import('./icons/stonjis/ChartBlack')),
  'stonji/arrow-up': dynamic(() => import('./icons/stonjis/ArrowUp')),
  'stonji/bars': dynamic(() => import('./icons/stonjis/Bars')),
  'stonji/pix': dynamic(() => import('./icons/stonjis/Pix')),
  'stonji/phone': dynamic(() => import('./icons/stonjis/Phone')),
  'stonji/phone-green': dynamic(() => import('./icons/stonjis/PhoneGreen')),
  'stonji/without-asterisc': dynamic(
    () => import('./icons/stonjis/WithoutAsterisc'),
  ),
  'stonji/percent': dynamic(() => import('./icons/stonjis/Percent')),
  'stonji/percent-black': dynamic(() => import('./icons/stonjis/PercentBlack')),
  'stonji/wheel': dynamic(() => import('./icons/stonjis/Wheel')),
  'stonji/credit-card': dynamic(() => import('./icons/stonjis/CreditCard')),
  'stonji/boleto': dynamic(() => import('./icons/stonjis/Boleto')),
  'stonji/money-green': dynamic(() => import('./icons/stonjis/MoneyGreen')),
  'stonji/money-black': dynamic(() => import('./icons/stonjis/MoneyBlack')),
  'stonji/face': dynamic(() => import('./icons/stonjis/Face')),
  'stonji/without-asterisc-green': dynamic(
    () => import('./icons/stonjis/WithoutAsteriscGreen'),
  ),
  'stonji/lock': dynamic(() => import('./icons/stonjis/Lock')),
  'stonji/smile': dynamic(() => import('./icons/stonjis/Smile')),
  'stonji/dollar-sign': dynamic(() => import('./icons/stonjis/DollarSign')),
  'stonji/white-heart': dynamic(
    () => import('./icons/stonjis/WhiteHeartWithGreenBg'),
  ),
  'stonji/package': dynamic(() => import('./icons/stonjis/Package')),
  'stonji/percent-green': dynamic(() => import('./icons/stonjis/PercentGreen')),
  'stonji/credit-card-front': dynamic(
    () => import('./icons/stonjis/CreditCardFront'),
  ),
  'stonji/pillar/just': dynamic(() => import('./icons/stonjis/pillars/just')),
  'stonji/pillar/call': dynamic(() => import('./icons/stonjis/pillars/call')),
  'stonji/pillar/integrations': dynamic(
    () => import('./icons/stonjis/pillars/integrations'),
  ),
  'stonji/pillar/app': dynamic(() => import('./icons/stonjis/pillars/app')),
  'stonji/chat': dynamic(() => import('./icons/stonjis/Chat')),
  'stonji/shield': dynamic(() => import('./icons/stonjis/Shield')),
  'stonji/check-green': dynamic(() => import('./icons/stonjis/CheckGreen')),

  'file-typed': dynamic(() => import('./icons/FileTyped')),
  play: dynamic(() => import('./icons/Play')),
  playBorder: dynamic(() => import('./icons/PlayBorder')),
  pause: dynamic(() => import('./icons/Pause')),
  logo: dynamic(() => import('./icons/Logo')),
  economicoValor: dynamic(() => import('./icons/EconomicoValor')),
  cnn: dynamic(() => import('./icons/CNN')),
  reclameAqui: dynamic(() => import('./icons/ReclameAqui')),
  arrowDown: dynamic(() => import('./icons/ArrowDown')),
  machine: dynamic(() => import('./icons/Machine')),
  award: dynamic(() => import('./icons/Award')),
  sliders: dynamic(() => import('./icons/Sliders')),
  plus: dynamic(() => import('./icons/Plus')),
  minus: dynamic(() => import('./icons/Minus')),
  'people-handshake': dynamic(() => import('./icons/PeopleHandshake')),
  boleto: dynamic(() => import('./icons/Boleto')),
  'check-round': dynamic(() => import('./icons/CheckRound')),
  'account-pj': 'account-pj',
  delivery: dynamic(() => import('./icons/Delivery')),
  fone: dynamic(() => import('./icons/Fone')),
  machinePos: dynamic(() => import('./icons/MachinePos')),
  'head-phone-soft': dynamic(() => import('./icons/HeadPhoneSoft')),
  receiptList43: dynamic(() => import('./icons/ReceiptList43')),
  grid: dynamic(() => import('./icons/Grid')),
  share: dynamic(() => import('./SVGs/1x/Socials/Share')),
  'hands-UsingApp-gray': dynamic(() => import('./icons/HandsUsingAppGray')),
  'stone-card-gray': dynamic(() => import('./icons/StoneCardGray')),
  'easy-turning-gray': dynamic(() => import('./icons/EasyTurningGray')),
  'coin-gray': dynamic(() => import('./icons/CoinGray')),
  'plus-circle': dynamic(() => import('./icons/PlusCircle')),
  'minus-circle': dynamic(() => import('./icons/MinusCircle')),
  'open-book': dynamic(() => import('./icons/OpenBook')),
  'check-phone': dynamic(() => import('./icons/CheckPhone')),
  'send-message': dynamic(() => import('./icons/SendMessage')),
  logoTon: dynamic(() => import('./icons/LogoTon')),
  headset: dynamic(() => import('./icons/Headset')),
  grid2: dynamic(() => import('./icons/Grid2')),
  /* please use following icons  */
  ...ArrowIcons,
  ...EmotionsHealthIcons,
  ...FinanceStoneIcons,
  ...ObjectsIcons,
  ...SecurityTechnology,
  ...SignalsIcons,
  ...SocialsIcons,
  ...ArrowIcons2x,
  ...EmotionsHealthIcons2x,
  ...FinanceStoneIcons2x,
  ...ObjectsIcons2x,
  ...SecurityTechnology2x,
  ...SignalsIcons2x,
  ...SocialsIcons2x,
  ...SpritedIcons,
} as const;

export type IconsNames = keyof typeof Icons;

export type IconProps = {
  name: IconsNames;
  className?: string;
};

const Icon: React.FC<IconProps> = ({ name, className }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const NewIcon: any = useMemo(() => Icons[name], [name]);

  return <NewIcon className={className} />;
};

export default Icon;
