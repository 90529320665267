'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FC, useContext } from 'react';

import Icon from '@/components/v3/Icon';
import { AmplitudeContext } from '@/contexts/amplitude';

const LogoStone: FC<{ colorLogo?: string }> = ({
  colorLogo = 'text-stone-500',
}) => {
  const pathname = usePathname();
  const { analytics } = useContext(AmplitudeContext);

  const handleAnalyticsPageSolucaoCompleta = () => {
    if (pathname === '/solucao-completa') {
      analytics?.track({
        event_type: 'Event',
        event_properties: {
          name: 'cta stone',
          description:
            'Evento disparado quando o usuário clicar no botão do logo na primeira section (hero)',
          section_reference: 'Solução completa pro seu tipo de negocio',
        },
      });
    }
  };
  return (
    <Link
      href="/"
      prefetch={false}
      aria-label="Stone"
      className={colorLogo}
      onClick={() => handleAnalyticsPageSolucaoCompleta()}
    >
      <Icon name="logo" className={`fill-current w-[92px] h-[24px]`} />
    </Link>
  );
};

export default LogoStone;
