'use client';

import { createContext, useEffect, useState } from 'react';

import IS_SERVER from '@/constants/isServer';
import { Ampli, ensureGetUserId } from '@/utils/amplitude';
import { ExperimentClient } from '@amplitude/experiment-js-client';

type VariantValue = string | null;

type ContextValue = {
  experiments: ExperimentClient | null;
  analytics: Ampli | null;
  useExperiment?: (
    experimentId: string,
    active: boolean,
    isFlag: boolean,
  ) => HookResponse;
};

type HookResponse = {
  activeVariant: VariantValue;
  payload: { [key: string]: [value: string] }[] | [];
};

const IS_FETCHING_VARIANT = 'FETCHING_OR_GETTING_VARIANT_FROM_STORAGE';
const EXPERIMENT_IS_DISABLED = 'EXPERIMENT_IS_DISABLED_IN_AMPLITUDE';
const CONTROL_VARIANT = null;

export const AmplitudeContext = createContext<ContextValue>({
  experiments: null,
  analytics: null,
  useExperiment: () => ({
    activeVariant: CONTROL_VARIANT,
    payload: [],
  }),
});

type ExperimentsState = {
  [key: string]: VariantValue;
};

export const ExperimentProvider: React.FC<{
  value: ContextValue;
  children: React.ReactNode;
}> = props => {
  const { value, children } = props;

  const [experimentsVariants, setExperimentsVariants] =
    useState<ExperimentsState>({});

  useEffect(() => {
    if (IS_SERVER) return;

    async function fetchOrGetVariantFromStorage(experimentId: string) {
      function getStoredVariant(): string | null {
        return localStorage.getItem(
          `amplitude-experiment-variant-${experimentId}`,
        );
      }

      function storeUserVariant(variant: string) {
        localStorage.setItem(
          `amplitude-experiment-variant-${experimentId}`,
          variant,
        );
      }

      if (!value?.experiments) return;

      // start amplitude experiments SDK
      await value?.experiments.start();

      // return experiment variant from localStorage if it's already fetched
      const storedVariant = getStoredVariant();
      if (
        storedVariant !== null &&
        storedVariant !== experimentsVariants[experimentId]
      ) {
        setExperimentsVariants(experiments => ({
          ...experiments,
          [experimentId]: String(storedVariant),
        }));

        return;
      }

      // generate user and fetch variant if user do not exists yet
      const user = value?.experiments.getUser();
      if (!user?.user_id) {
        const clientSideUserId = ensureGetUserId();

        value?.experiments.setUser({
          user_id: clientSideUserId,
        });

        // call amplitude asking variants of all experiments for specific user;
        await value?.experiments.fetch({
          user_id: clientSideUserId,
        });
      }

      // get variant for this user stored in amplitude SDK
      const feature = value?.experiments.variant(experimentId);

      // trigger experiment variant exposure event in amplitude
      value?.experiments.exposure(experimentId);

      // update experiment variant in localStorage
      const variant = feature?.value;
      if (variant) {
        storeUserVariant(String(variant));
      }

      // update experiment variant in context state
      setExperimentsVariants(experiments => ({
        ...experiments,
        [experimentId]: variant ? String(variant) : EXPERIMENT_IS_DISABLED,
      }));
    }

    Object.entries(experimentsVariants).forEach(([experiment, variant]) => {
      const neverTriedToGetExperimentVariant = variant === null;

      if (
        neverTriedToGetExperimentVariant &&
        ![EXPERIMENT_IS_DISABLED, IS_FETCHING_VARIANT].includes(
          String(experimentsVariants[experiment]),
        )
      ) {
        setExperimentsVariants(experiments => ({
          ...experiments,
          [experiment]: IS_FETCHING_VARIANT,
        }));
        fetchOrGetVariantFromStorage(experiment);
      }
    });
  }, [experimentsVariants, value?.experiments]);

  return (
    <AmplitudeContext.Provider
      value={{
        experiments: value?.experiments || null,
        analytics: value?.analytics || null,
        useExperiment: (
          experimentIdParam: string,
          active: boolean,
          isFlag: boolean,
        ) => {
          useEffect(() => {
            isFlag &&
              localStorage.removeItem(
                `amplitude-experiment-variant-${experimentIdParam}`,
              );
            if (
              active &&
              !experimentsVariants.hasOwnProperty(experimentIdParam)
            ) {
              setExperimentsVariants(experiments => {
                return {
                  ...experiments,
                  [experimentIdParam]: null,
                };
              });
            }
          }, [active, experimentIdParam]);

          const STATE_VARIANT = experimentsVariants[experimentIdParam];

          const canReturnStateVariant = ![
            IS_FETCHING_VARIANT,
            EXPERIMENT_IS_DISABLED,
          ].includes(String(STATE_VARIANT));

          const payload =
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (value?.experiments as any)?.variants?.cache[experimentIdParam]
              ?.payload || [];

          return {
            activeVariant: canReturnStateVariant
              ? STATE_VARIANT
              : CONTROL_VARIANT,
            payload,
          };
        },
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};
